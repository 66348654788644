// =============================================================================
//
//  General
//
//  @version    0.0.1
//  @package    Protein Fabrikken
//  @author     Jovan Poplasen <jovan@improove.se>
//
// =============================================================================

@media screen and (min-width: $desktop-width) {
    .full-width {
        padding: 0;
    }
}
